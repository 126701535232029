.markets-container {
  max-width: 1224px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  padding-left: 12px;
}

.market {
  display: flex;
  width: calc(50% - 12px);
  margin: 12px 12px 0 0;
  background-color: #fff;
  border: 1px solid #666;
  border-radius: 4px;
  h3 {
    margin-bottom: 12px;
  }
}

.market-image {
  object-fit: cover;
  max-height: 172px;
  img {
    max-height: 172px;
  }
}

.market-data {
  padding: 16px;
}

.market-data-key {
  font-weight: bold;
}

@media only screen and (max-width: 1288px) {
  .market {
    width: 100%;
    margin: 12px 24px 0 24px;
  }
  .markets-container {
    padding-left: 0px;
  }
}

@media only screen and (max-width: 400px) {
  .market {
    flex-direction: column;
    margin: 12px 12px 0 12px;
  }
  .market-image {
    max-height: none;
    img {
      max-width: 100%;
      max-height: none;
    }
  }
}
