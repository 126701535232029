* {
  margin: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  height: 100%;
  font-family: $font-main;
  background-color: #edf2f3;
  font-weight: 400;
  line-height: 1.5;
  color: #263b3f;
  -webkit-font-smoothing: antialiased;
}

a {
  color: #263b3f;
  text-decoration: none;
}

select {
  height: 32px;
  border-radius: 4px;
  border: none;
  outline: none;
  background-color: #ccc;
  padding: 0 6px;
  font-size: 18px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.col_1 {
  width: calc(100% * (1 / 12));
}
.col_2 {
  width: calc(100% * (2 / 12));
}
.col_3 {
  width: 25%;
}
.col_4 {
  width: calc(100% * (4 / 12));
}
.col_5 {
  width: calc(100% * (5 / 12));
}
.col_6 {
  width: 50%;
}
.col_7 {
  width: calc(100% * (7 / 12));
}
.col_8 {
  width: calc(100% * (6 / 12));
}
