#spread_container {
  max-width: 1224px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.spread_header {
  background: #08373f;
  color: #fff;
  font-size: 12px;
  padding: 7px 0 7px 0;
  margin-top: 8px;
  display: flex;
  align-items: center;
}

.spread_contract-container {
  display: flex;
  width: 50%;
  .market {
    width: 100%;
  }
}
.spread_contract-container:nth-child(even) {
  .market {
    margin: 12px 0 0 0;
  }
}

.spread_markets-container {
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (max-width: 950px) {
  .spread_contract-container {
    width: 100%;
  }
}
