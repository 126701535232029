header {
  .main-header {
    background-image: linear-gradient(307deg, #07414b, #083037);
    height: 45px;
    align-items: center;
    nav {
      max-width: 1200px;
      height: 100%;
      display: flex;
      align-items: center;
      margin: 0 auto;
      padding: 0 24px;
    }
  }
  .sub-header {
    height: 45px;
    align-items: center;
    background: #204b52;
    font-size: 12px;
    nav {
      display: flex;
      justify-content: flex-end;
      max-width: 1200px;
      height: 100%;
      display: flex;
      align-items: center;
      margin: 0 auto;
      padding: 0 24px;
      a {
        color: #fafafa;
        margin-right: 24px;
      }
      a:last-child {
        margin-right: 0;
      }
    }
  }
}

.logo span {
  vertical-align: 6px;
  font-size: 27px;
  font-weight: bold;
  letter-spacing: -0.1em;
  color: #fd2733;
  margin-left: 2px;
}
