// variables
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap");
@import "variables";

// components
@import "components/home";
@import "components/header";
@import "components/main";
@import "components/marketDetails";
@import "components/marketDetailsInfo";
@import "components/contract";
@import "components/spread";
@import "components/slider";

//sass --watch sass:src/styles
