.marketDetails_title {
  font-size: 26px;
  font-family: Roboto, sans-serif;
  vertical-align: middle;
  font-size: 26px;
  line-height: 1.1;
  letter-spacing: 0.3px;
  font-weight: 500;
  padding: 24px 0;
}
.marketDetails {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

@media only screen and (max-width: 400px) {
  .marketDetails {
    padding: 12px 8px;
  }
  .marketDetails_title {
    padding: 12px 12px;
  }
}
