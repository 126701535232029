.market-details_info {
  [class^="col_"] {
    display: flex;
    justify-content: center;
  }
  .contract_row {
    padding: $dim_market-details_info_padding;
    font-size: 32px;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
  }
  .contracts_header {
    padding: $dim_market-details_info_padding;
  }
}

.alert_value {
  height: 32px;
  width: 84px;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: none;
  font-size: 24px;
  text-align: center;
}

.refresh {
  height: 32px;
  width: auto;
  margin-left: 5px;
  &:hover {
    cursor: pointer;
  }
}

.loading {
  animation-name: spin;
  animation-duration: 1200ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes spin {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@media only screen and (max-width: 400px) {
  .market-details_info .contract_row {
    padding-left: 12px;
    font-size: 16px;
  }

  .contracts_header {
    padding-left: 12px;
    .risk {
      padding-left: 16px;
    }
    .sumNos {
      padding-left: 0;
    }
  }
}
