.contracts_header {
  background: #08373f;
  color: #fff;
  font-size: 12px;
  padding: 7px 0 7px 90px;
  margin-top: 8px;
  display: flex;
  align-items: center;
}

.contract_row {
  display: flex;
  align-items: center;
  height: 80px;
  box-shadow: 0 2px 12px 0 rgba(161, 172, 202, 0.15);
  font-size: 20px;
  font-weight: 300;
  background-color: #fff;
  font-family: Roboto, sans-serif;
}

.contract_image {
  height: 80px;
}

.contract_data {
  height: 100%;
  width: calc(100% - 80px);
  display: flex;
  align-items: center;
}

.contract_name {
  padding-left: 12px;
}

.contract_bestNoQuantity {
  font-size: 32px;
  font-weight: 300;
}
.contract_bestNoPrice {
  font-size: 32px;
  font-weight: 300;
}
.contract_bestNoSpread {
  font-size: 32px;
  font-weight: 300;
}

.contract_quantity {
  font-size: 32px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

span.contract_quantity {
  margin-left: 3px;
}

input.contract_quantity {
  height: 36px;
  width: 84px;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: none;
}

@media only screen and (max-width: 400px) {
  .contract_row {
    font-size: 14px;
    .contract_bestNoPrice,
    .contract_bestNoSpread,
    .contract_quantity,
    .contract_bestNoQuantity {
      font-size: 16px;
    }
  }
  .contract_image {
    display: none;
  }
  .contract_data {
    width: 100%;
  }
}
